// import * as React from "react";
// import Container from "@mui/material/Container";
// import DigiHealthColoredLogo from "../../Assets/cosmosLogocolor.png";
// import UserLogo from "../../Assets/profile.svg";
// import NotificationLogo from "../../Assets/notification.svg";
// import LogoutLogo from "../../Assets/logout.svg";
// import { useNavigate } from "react-router-dom";
// import IconButton from '@mui/material/IconButton';
// import FullscreenIcon from '@mui/icons-material/Fullscreen';
// import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
// import screenfull from 'screenfull';

// const AdministratorNavbar = () => {
//   const navigate = useNavigate();
//   const LoginUserType = sessionStorage.getItem("LoginUserType")
//   const LoginUserFname = sessionStorage.getItem("LoginUserFname")
//   const LoginUserLname = sessionStorage.getItem("LoginUserLname")
//   const handleLogout = () => {
//     sessionStorage.clear();
//     LoginUserType !== "Patient" ? navigate("/providerlogin") : navigate("/");
//   };
  

//   const handleFullscreenToggle = () => {
//     if (screenfull.isEnabled) {
//       screenfull.toggle();
//     }
//   };

//   return (
//     <Container maxWidth="xl" style={{ padding: "0px" }}>
//       <div style={{ width: "100%" }}>
//         <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
//           <div>
//             <img
//               src={DigiHealthColoredLogo}
//               alt=""
//               style={{ width: "150px",  }}
//             />
//           </div>
//           <div className="nav-bar-other-logo-items">
//           <IconButton color="inherit" onClick={handleFullscreenToggle}>
//               {screenfull.isFullscreen ? <FullscreenExitIcon  style={{height:"40px",width:"50px",color:"blue"}}/> : <FullscreenIcon style={{height:"40px",width:"50px",color:"blue"}} />}
//             </IconButton>
//             <img src={NotificationLogo} alt="" />
//             <img src={UserLogo} alt="" />
//             <p style={{margin:"0px",color:"#2662f0",fontSize:"18px",fontWeight:"600"}}>{LoginUserFname}{" "}{LoginUserLname}</p>
//             <img
//               src={LogoutLogo}
//               alt=""
//               onClick={handleLogout}
//               style={{ cursor: "pointer" }}
//             />
           
//           </div>
//         </div>
//       </div>

//     </Container>
//   );
// };

// export default AdministratorNavbar;



import * as React from "react";
import Container from "@mui/material/Container";
import DigiHealthColoredLogo from "../../Assets/cosmosLogocolor.png";
import UserLogo from "../../Assets/profile.svg";
import NotificationLogo from "../../Assets/notification.svg";
import LogoutLogo from "../../Assets/logout.svg";
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import screenfull from 'screenfull';

const AdministratorNavbar = () => {
  const navigate = useNavigate();
  const LoginUserType = sessionStorage.getItem("LoginUserType")
  const LoginUserFname = sessionStorage.getItem("LoginUserFname")
  const LoginUserLname = sessionStorage.getItem("LoginUserLname")
  const handleLogout = () => {
    sessionStorage.clear();
    LoginUserType !== "Patient" ? navigate("/providerlogin") : navigate("/");
  };
  

  const handleFullscreenToggle = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  };

  return (
    <Container maxWidth="xl" style={{ padding: "0px" }}>
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <img
              src={DigiHealthColoredLogo}
              alt=""
              style={{ width: "150px",  }}
            />
          </div>
          <div className="nav-bar-other-logo-items">
          <IconButton className="fullscreen" color="inherit" onClick={handleFullscreenToggle}>
              {screenfull.isFullscreen ? <FullscreenExitIcon  style={{height:"40px",width:"50px",color:"blue"}}/> : <FullscreenIcon style={{height:"40px",width:"50px",color:"blue"}} />}
            </IconButton>
            <img className="notification" src={NotificationLogo} alt="" />
            <img className="user_logo" src={UserLogo} alt="" />
            <p style={{margin:"0px",color:"#2662f0",fontSize:"16px",fontWeight:"600"}}>{LoginUserFname}{" "}{LoginUserLname}</p>
            <img 
              src={LogoutLogo}
              alt=""
              onClick={handleLogout}
              style={{ cursor: "pointer" }}
            />
           
          </div>
        </div>
      </div>

    </Container>
  );
};

export default AdministratorNavbar;
